import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider, useSelector } from "react-redux";
import allReducers from "./reducers";
import thunk from "redux-thunk";
import { getEducations } from "./actions/educationAction";
import { getExperiences } from "./actions/experienceAction";
import { getprojects } from "./actions/projectAction";
import { getSkills } from "./actions/skillAction";
import api from "./apis/serverApi";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(allReducers, composeEnhancer(applyMiddleware(thunk)));

// localStorage'dan verileri çek
const cachedEducations = JSON.parse(localStorage.getItem("cachedEducations"));
const cachedExperiences = JSON.parse(localStorage.getItem("cachedExperiences"));
const cachedProjects = JSON.parse(localStorage.getItem("cachedProjects"));
const cachedSkills = JSON.parse(localStorage.getItem("cachedSkills"));

// Eğer önbellekte veri varsa, Redux state'ine ekle
if (cachedEducations) {
  store.dispatch({ type: "SET_EDUCATIONS", payload: cachedEducations });
}
if (cachedExperiences) {
  store.dispatch({ type: "SET_EXPERIENCES", payload: cachedExperiences });
}
if (cachedProjects) {
  store.dispatch({ type: "SET_PROJECTS", payload: cachedProjects });
}
if (cachedSkills) {
  store.dispatch({ type: "SET_SKILLS", payload: cachedSkills });
}

// API'den verileri çek ve localStorage'a kaydet
store.dispatch(getEducations()).then(() => {
  const educations = store.getState().educations;
  localStorage.setItem("cachedEducations", JSON.stringify(educations));
});
store.dispatch(getExperiences()).then(() => {
  const experiences = store.getState().experiences;
  localStorage.setItem("cachedExperiences", JSON.stringify(experiences));
});
store.dispatch(getprojects()).then(() => {
  const projects = store.getState().projects;
  localStorage.setItem("cachedProjects", JSON.stringify(projects));
});
store.dispatch(getSkills()).then(() => {
  const skills = store.getState().skills;
  localStorage.setItem("cachedSkills", JSON.stringify(skills));
});

const user = JSON.parse(localStorage.getItem("userData"));
if (user !== null) {
  api.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();