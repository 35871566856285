import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import serverApi from "../../../apis/serverApi";
import { getMessages } from "../../../actions/messageAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TwitterFeed from "./TwitterFeed"; // TwitterFeed componentini ekledik

const Contacts = ({ reff }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await serverApi.post("/messages", data);
      setLoading(false);
      toast.success("Sent Successfully", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      reset();
      dispatch(getMessages());
    } catch (error) {
      toast.error("An error happened while sending the message", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  return (
    <div style={{ backgroundColor: "#fff", padding: "4rem 0", minHeight: "100vh" }}>
      <section
        ref={reff}
        id="contact"
        style={{ padding: "2rem 0", maxWidth: "1200px", margin: "0 auto" }}
      >
        <div className="container" style={{ paddingBottom: "5rem" }}>
          <h2
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              marginBottom: "3rem",
              textAlign: "center",
              color: "#333",
            }}
          >
            {t('fo8.19')}
          </h2>

          <div className="row">
            {/* Form Alanı */}
            <div className="col-md-9 mb-md-0 mb-5 px-md-5">
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: "2rem",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  maxHeight: "600px", // Form yüksekliği sınırlandırıldı
                  overflowY: "auto", // İçerik fazla ise kaydırılabilir yapıldı
                }}
              >
                <div className="row py-md-2">
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <label htmlFor="name" style={{ fontWeight: "600", color: "#555" }}>
                        {t('fo15.26')}
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          padding: "0.75rem",
                          marginBottom: "1rem",
                        }}
                        {...register("name", {
                          required: "Name Required",
                          maxLength: 20,
                          minLength: 5,
                        })}
                      />
                      {errors.name && errors.name.type === "required" && (
                        <div style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                          You must enter your name
                        </div>
                      )}
                      {errors.name && errors.name.type === "minLength" && (
                        <div style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                          Your name must be at least 5 characters
                        </div>
                      )}
                      {errors.name && errors.name.type === "maxLength" && (
                        <div style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                          Your name must be at most 20 characters
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <label htmlFor="email" style={{ fontWeight: "600", color: "#555" }}>
                        E-mail
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="form-control"
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          padding: "0.75rem",
                          marginBottom: "1rem",
                        }}
                        {...register("email", {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <div style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                          You must enter your email
                        </div>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <div style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                          You must enter a valid email
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="md-form mb-0">
                      <label htmlFor="subject" style={{ fontWeight: "600", color: "#555" }}>
                        {t('fo16.27')}
                      </label>
                      <input
                        type="text"
                        id="subject"
                        className="form-control"
                        name="subject"
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          padding: "0.75rem",
                          marginBottom: "1rem",
                        }}
                        {...register("subject", {
                          required: true,
                          maxLength: 50,
                          minLength: 5,
                        })}
                      />
                      {errors.subject && errors.subject.type === "required" && (
                        <div style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                          You must enter a subject
                        </div>
                      )}
                      {errors.subject && errors.subject.type === "minLength" && (
                        <div style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                          Subject must be at least 5 characters
                        </div>
                      )}
                      {errors.subject && errors.subject.type === "maxLength" && (
                        <div style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                          Subject must be at most 50 characters
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="md-form">
                      <label htmlFor="message" style={{ fontWeight: "600", color: "#555" }}>
                        {t('fo17.28')}
                      </label>
                      <textarea
                        type="text"
                        name="message"
                        id="message"
                        rows="3"
                        className="form-control md-textarea"
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          padding: "0.75rem",
                          marginBottom: "1rem",
                        }}
                        {...register("message", {
                          required: true,
                          minLength: 20,
                        })}
                      />
                      {errors.message && errors.message.type === "required" && (
                        <div style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                          You must enter a message
                        </div>
                      )}
                      {errors.message && errors.message.type === "minLength" && (
                        <div style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                          Message must be at least 20 characters
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center text-md-left mt-3">
                    {!loading && (
                      <input
                        type="submit"
                        className="btn btn-primary"
                        value="Gönder"
                        style={{
                          backgroundColor: "#007bff",
                          border: "none",
                          padding: "0.75rem 2rem",
                          fontSize: "1rem",
                          fontWeight: "600",
                          transition: "background-color 0.3s ease",
                        }}
                      />
                    )}
                    {loading && (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>

            {/* Sağdaki Twitter Feed */}
            <div className="col-md-3">
              <div
                style={{
                  maxHeight: "600px", // TwitterFeed yüksekliği sınırlandırıldı
                  overflowY: "auto", // İçerik fazla ise kaydırılabilir yapıldı
                  padding: "1rem",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <TwitterFeed />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contacts;