import React, { useEffect, useState } from "react";
import axios from "axios";
import "./twit.css";

const TwitterFeed = () => {
  const [tweets, setTweets] = useState([]); // Tweetleri saklamak için state
  const [loading, setLoading] = useState(true); // Yüklenme durumunu takip etmek için state
  const [error, setError] = useState(null); // Hata durumunu takip etmek için state

  // Component mount olduğunda tweetleri çek
  useEffect(() => {
    const fetchTweets = async () => {
      try {
        const response = await axios.get("http://localhost:4000/api"); // Backend endpoint'in adresi
        setTweets(response.data); // Tweetleri state'e kaydet
        setLoading(false); // Yüklenme tamamlandı
      } catch (err) {
        setError("Tweetler yüklenirken bir hata oluştu."); // Hata durumunda mesaj göster
        setLoading(false); // Yüklenme tamamlandı
      }
    };

    fetchTweets();
  }, []);

  // Eğer hala yükleme devam ediyorsa, yükleme göstergesi göster
  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  // Eğer bir hata oluştuysa, hata mesajını göster
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ padding: "1rem", fontFamily: "Arial, sans-serif" }}>
      <h2 style={{ marginBottom: "1rem", color: "#1da1f2" }}>Tweetlerim</h2>
      <ul style={{ listStyle: "none", padding: 0 }}>
        {/* Tweetleri liste olarak göster */}
        {tweets.map((tweet, index) => (
          <li
            key={index}
            style={{
              backgroundColor: "#f5f8fa",
              padding: "1rem",
              marginBottom: "1rem",
              borderRadius: "10px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* HTML etiketlerini güvenli bir şekilde render et */}
            <p
              dangerouslySetInnerHTML={{ __html: tweet.text }}
              style={{ margin: 0, color: "#14171a",
    '& a': {
      color: '#1DA1F2 !important',
      textDecoration: 'underline',
      fontWeight: 500 }}}
            />
            <small style={{ display: "block", marginTop: "0.5rem", color: "#657786" }}>
              {new Date(tweet.created_at).toLocaleString()} {/* Tweet tarihini göster */}
            </small>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TwitterFeed;