import React from "react";
import "./styles.css";
import homeImage from "../../../assets/images/dev.svg";
import { useTranslation } from "react-i18next";

const PageIntro = ({ reff }) => {
  const { t } = useTranslation();
  return (
    <section ref={reff}>
      <div className="container">
        <div className="row">
          {/* Sol Taraf: Metin İçeriği */}
          <div id="intro-section" className="col">
            <div className="rgba-gradient d-flex justify-content-center align-items-center header">
              <div className="container px-md-3 px-sm-0">
                <div className="row">
                  <div className="col-md-12 mb-4 text-center">
                    <h3
                      className="display-3 fw-bold pt-md-5 pt-5"
                      style={{ color: "#2A083F" }}
                    >
                      {t('intro.11')}
                    </h3>
                    <hr className="my-4 text-light" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Sağ Taraf: Resim İçeriği */}
          <div className="col d-none d-lg-inline">
            <div className="d-flex justify-content-center align-items-center">
              <img
                className="img-fluid mt-7 ms-5 dev-img" // Burada mt-5 ekledik
                src={homeImage}
                alt="home"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageIntro;